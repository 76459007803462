///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Button */
#homePage {
	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'border-color #{_duration(transition)} ease'
		));
		background-color: transparent;
		border: solid 1px !important;
		border-color: _palette(border) !important;
		border-radius: 3em;
		color: _palette(fg-bold) !important;
		cursor: pointer;
		display: inline-block;
		font-size: 0.6em;
		font-weight: _font(weight-bold);
		height: calc(4.75em + 2px);
		letter-spacing: _font(kerning-alt);
		line-height: 4.75em;
		outline: 0;
		padding: 0 3.75em;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&:after {
			@include vendor('transform', 'scale(0.25)');
			@include vendor('pointer-events', 'none');
			@include vendor('transition', (
				'opacity #{_duration(transition)} ease',
				'transform #{_duration(transition)} ease'
			));
			background: _palette(fg-bold);
			border-radius: 3em;
			content: '';
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&.icon {
			&:before {
				margin-right: 0.75em;
			}
		}

		&.fit {
			width: 100%;
		}

		&.small {
			font-size: 0.4em;
		}

		&.large {
			font-size: 0.8em;
		}

		&.primary {
			background-color: _palette(fg-bold);
			color: _palette(bg) !important;

			&:after {
				display: none;
			}
		}

		&.disabled,
		&:disabled {
			cursor: default;
			opacity: 0.5;
			@include vendor('pointer-events', 'none');
		}

		&:hover {
			border-color: _palette(fg) !important;

			&:after {
				opacity: 0.05;
				@include vendor('transform', 'scale(1)');
			}

			&:active {
				border-color: _palette(fg-bold) !important;

				&:after {
					opacity: 0.1;
				}
			}
		}
	}
}
