.resizeContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.content {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
