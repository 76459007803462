///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Wrapper */

.wrapper {
	position: relative;

	> .inner {
		@include padding(5em, 5em);

		@include breakpoint('<=xlarge') {
			@include padding(4em, 4em);
		}

		@include breakpoint('<=small') {
			@include padding(3em, 2em);
		}
	}

	&.alt {
		background-color: _palette(bg-alt);
	}

	&.style1 {
		background-color: _palette(accent1);
	}

	&.style1Alt {
		background-color: _palette(accent1-alt);
	}

	&.style2 {
		background-color: _palette(accent2);
	}

	&.style2-alt {
		background-color: _palette(accent2-alt);
	}

	&.style3 {
		background-color: _palette(accent3);
	}

	&.style3-alt {
		background-color: _palette(accent3-alt);
	}
}
