.root {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.drawer {
  position: absolute;
  will-change: transform;
  z-index: 2000;
}

.drawerLeft {
  width: 250px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
}

.drawerRight {
  @extend .drawerLeft;
  left: auto;
  right: 0;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  will-change: opacity;
}

.rootOpen {
  .backdrop {
    opacity: 1;
    animation: fade-in 300ms linear forwards;
  }
  .drawerLeft {
    animation: slide-in-left 300ms linear forwards;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in-left {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0, 0);
  }
}
