///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

#homePage {

	&, input, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 16.5pt;
		font-weight: _font(weight);
		line-height: 1.75;

		@include breakpoint('<=xlarge') {
			font-size: 13pt;
		}

		@include breakpoint('<=large') {
			font-size: 12pt;
		}

		@include breakpoint('<=xxsmall') {
			font-size: 11pt;
		}
	}

	a {
		@include vendor('transition', (
			'color #{_duration(transition)} ease',
			'border-bottom-color #{_duration(transition)} ease'
		));
		border-bottom: dotted 1px _palette(fg-light);
		color: inherit;
		text-decoration: none;

		&:hover {
			border-bottom-color: transparent;
			color: _palette(fg-bold);
		}
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 0.25) 0;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	h1 {
		font-size: 2.75em;

		&.major {
			margin: 0 0 (_size(element-margin) * 0.65) 0;
			position: relative;
			padding-bottom: 0.35em;

			&:after {
				@include vendor('background-image', 'linear-gradient(to right, #{_palette(accent1)}, #{_palette(accent3)})');
				@include vendor('transition', 'max-width #{_duration(transition)} ease');
				border-radius: 0.2em;
				bottom: 0;
				content: '';
				height: 0.05em;
				position: absolute;
				right: 0;
				width: 100%;
			}
		}
	}

	h2 {
		font-size: 1.75em;
	}

	h3 {
		font-size: 1.1em;
	}

	h4 {
		font-size: 1em;
	}

	h5 {
		font-size: 0.8em;
	}

	h6 {
		font-size: 0.6em;
	}

	@include breakpoint('<=small') {
		h1 {
			font-size: 2em;
		}

		h2 {
			font-size: 1.25em;
		}

		h3 {
			font-size: 1em;
		}

		h4 {
			font-size: 0.8em;
		}

		h5 {
			font-size: 0.6em;
		}

		h6 {
			font-size: 0.6em;
		}
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid (_size(border-width) * 4) _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		background: _palette(border-bg);
		border-radius: _size(border-radius);
		border: solid _size(border-width) _palette(border);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75em;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid _size(border-width) _palette(border);
		margin: _size(element-margin) 0;

		&.major {
			margin: (_size(element-margin) * 1.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

}
