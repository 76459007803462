///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			padding: 0 0.75em 0 0;

			&:last-child {
				padding-right: 0;
			}

			> a, > span {
				border: 0;
				@include icon;

				.label {
					display: none;
				}
			}
		}
	}