@import '../../responsive.scss';

@mixin gutter($size) {
  margin-left: -$size;
  margin-right: -$size;

  > * {
    padding: $size;
  }
}

@mixin responsive-gutter($size) {
  @include gutter($size);

  @include media-md-down {
    @include gutter($size / 1.5);
  }

  @include media-sm-down {
    @include gutter($size / 2);
  }
}

.gutterSmall {
  @include responsive-gutter(10px);
}

.gutterMedium {
  @include responsive-gutter(20px);
}

.gutterLarge {
  @include responsive-gutter(30px);
}
