@import '../../palette.scss';

.loginPage {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $accent1;
  position: relative;
  overflow: hidden;
}

.loginCard {
  padding: 4em 5em 3.5em 5em;
  background-color: darken(white, 1%);
  color: rgb(60, 60, 60);
  position: relative;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  z-index: 1;
}

.logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -35%) scale(2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginForm {
  input[name="username"],
  input[name="password"] {
    height: 40px;
  }

  button[type="submit"] {
    width: 100%;
  }
}

.background {
  z-index: 0;
}
