///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

#homePage {

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 _size(element-margin) 0;
		width: 100%;

		td {
			padding: 0.75em 0.75em;
		}

		th {
			color: _palette(fg-bold);
			font-size: 1em;
			font-weight: _font(weight-bold);
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		thead {
			border-bottom: solid (_size(border-width) * 2) _palette(border);
		}

		tfoot {
			border-top: solid (_size(border-width) * 2) _palette(border);
		}

		&.alt {
			border-collapse: separate;

			thead {
				border-bottom: 0;
			}

			tfoot {
				border-top: 0;
			}
		}
	}

}
