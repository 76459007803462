@import '../../common.scss';

.appLayout {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.content {
  overflow: auto;
  @include ios-smooth-scroll-fix();
}
