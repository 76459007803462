///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

#sidebar {
	@include padding(2.5em, 2.5em);
	background: _palette(bg);
	cursor: default;
	height: 100vh;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	text-align: right;
	top: 0;
	width: _size(sidebar-width);
	z-index: _misc(z-index-base);

	> .inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100%;
	}

	nav {
		> ul {
			list-style: none;
			padding: 0;

			> li {
				margin: 1.5em 0 0 0;

				&:first-child {
					margin: 0;
				}
			}
		}

		a {
			border: 0;
			display: block;
			font-size: 0.7em;
			font-weight: _font(weight-bold);
			letter-spacing: _font(kerning-alt);
			line-height: 1.75;
			padding: 1.35em 0;
			position: relative;
			text-decoration: none;
			text-transform: uppercase;

			&:before,
			&:after {
				border-radius: 0.2em;
				bottom: 0;
				content: '';
				height: 0.2em;
				position: absolute;
				right: 0;
				width: 100%;
			}

			&:before {
				background: lighten(_palette(bg), 5);
			}

			&:after {
				background-image: linear-gradient(to right, _palette(accent1), _palette(accent3));
				transition: max-width _duration(transition) ease;
				max-width: 0;
			}

			&:global(.active) {
				&:after {
					max-width: 100%;
				}
			}
		}
	}

	@include breakpoint('<=large') {
		height: _size(sidebar-height);
		left: 0;
		line-height: _size(sidebar-height);
		overflow: hidden;
		padding: 0;
		text-align: center;
		top: 0;
		width: 100%;

		> .inner {
			flex-direction: row;
			align-items: stretch;
			height: inherit;
			line-height: inherit;
		}

		nav {
			height: inherit;
			line-height: inherit;

			ul {
				@include vendor('display', 'flex');
				height: inherit;
				line-height: inherit;
				margin: 0;

				li {
					display: block;
					height: inherit;
					line-height: inherit;
					margin: 0 0 0 2em;
					padding: 0;
				}
			}

			a {
				height: inherit;
				line-height: inherit;
				padding: 0;

				&:after {
					background-image: none;
					background-color: _palette(accent3);
				}
			}
		}
	}

	@include breakpoint('<=small') {
		display: none;
	}
}
