.displayStyle {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }
}

.content {
  padding: 2rem;
}

.actions {
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
}
