@import '../../responsive.scss';

.hero {
  overflow: hidden;
  background-attachment: fixed;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-md-down {
    min-height: 45vh;
  }

  @include media-sm-down {
    margin-top: 25px;
  }

  img {
    width: 40%;
  }
}

.iphone {
  @include media-md-down {
    display: none;
  }
  @include media-lg-up {
    padding: 2em 2em 1em 2em;
  }
}

.howItWorks {
  img {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }
}

.features {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 2em;

  $border: solid 1px rgba(255, 255, 255, 0.15);

  > * {
    position: relative;
    flex-basis: 50%;
    padding: 3.5em 3em 1em 7em;

    :global(.bp3-icon) {
      position: absolute;
      transform: translate(-300%, 50%) scale(2);
    }
  }

  > *:nth-child(4n-3) {
    border-left: $border;
    border-top: $border;
    border-bottom: $border;
  }

  > *:nth-child(4n-2) {
    border: $border;
  }

  > *:nth-child(4n-1) {
    border-left: $border;
    border-bottom: $border;
  }

  > *:nth-child(4n) {
    border-left: $border;
    border-right: $border;
    border-bottom: $border;
  }

  @include media-md-down {
    flex-direction: column;
  }
}
