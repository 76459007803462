@import '../../responsive.scss';

.appBar {

}

.desktopNav {
  @include media-sm-down {
    display: none;
  }
}

.mobileNav {
  display: none;
  @include media-sm-down {
    display: flex;
  }
}
