.tenantSettings {
  hr {
    border: none;
    border-bottom: 1px solid lightgray;
  }

  td {
    vertical-align: middle !important;
  }
}
