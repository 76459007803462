$media-xs-width: 375px;
$media-sm-width: 576px;
$media-md-width: 768px;
$media-lg-width: 992px;
$media-xl-width: 1200px;

/*
 * You can use the following media mixins like the following:
 * @include media-sm {
 *   h1 {
 *     font-size: 14px;
 *   }
 * }
 */

@mixin media-xs-up {
  @media (min-width: $media-xs-width) {
    @content;
  }
}

@mixin media-sm-up {
  @media (min-width: $media-sm-width) {
    @content;
  }
}

@mixin media-md-up {
  @media (min-width: $media-md-width) {
    @content;
  }
}

@mixin media-lg-up {
  @media (min-width: $media-lg-width) {
    @content;
  }
}

@mixin media-xl-up {
  @media (min-width: $media-xl-width) {
    @content;
  }
}

@mixin media-xs-down {
  @media (max-width: $media-xs-width - 1px) {
    @content;
  }
}

@mixin media-sm-down {
  @media (max-width: $media-sm-width - 1px) {
    @content;
  }
}

@mixin media-md-down {
  @media (max-width: $media-md-width - 1px) {
    @content;
  }
}

@mixin media-lg-down {
  @media (max-width: $media-lg-width - 1px) {
    @content;
  }
}

@mixin media-xl-down {
  @media (max-width: $media-xl-width - 1px) {
    @content;
  }
}
