@import '../../../responsive.scss';

.homeNavigation {

}

.mobileNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #312450;

  @include media-md-up {
    display: none;
  }
}

.logo {
  display: flex;
  align-items: center;

  img {
    width: 7em !important;
  }
}

.mobileNavDrawer {
  background-color: #312450;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 50px;

  > * {
    margin-bottom: 2em;
  }

  a {
    border: 0;
    display: block;
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: 0.25em;
    line-height: 1.75;
    padding: 1.35em 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &:before,
    &:after {
      border-radius: 0.2em;
      bottom: 0;
      content: '';
      height: 0.2em;
      position: absolute;
      right: 0;
      width: 100%;
    }

    &:before {
      background: lighten(#312450, 5);
    }

    &:after {
      background-image: linear-gradient(to right, #54379d, #b74e91);
      transition: max-width 0.2s ease;
      max-width: 0;
    }

    &:global(.active) {
      &:after {
        max-width: 100%;
      }
    }
  }
}
