@import './responsive.scss';

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.justifyEnd {
  justify-content: flex-end;
}

.flex1 {
  flex: 1;
}

.flexWrap {
  flex-wrap: wrap;
}

.spaceChildrenHorizontal {
  > * {
    margin-right: 1rem;

    @include media-sm-down {
      margin-right: 0.5rem;
    }
  }

  > :last-child {
    margin-right: 0;
  }
}

.spaceChildrenVertical {
  > * {
    margin-bottom: 1rem;

    @include media-sm-down {
      margin-bottom: 0.5rem;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.spaceChildrenHorizontalLarge {
  > * {
    margin-right: 4rem;

    @include media-sm-down {
      margin-right: 3rem;
    }
  }

  > :last-child {
    margin-right: 0;
  }
}

.spaceChildrenVerticalLarge {
  > * {
    margin-bottom: 4rem;

    @include media-sm-down {
      margin-bottom: 3rem;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

/*
 * ios should make overflow areas use the default
 * scrolling behaviour but it doesn't because safari...
 * this mixin uses their custom webkit property to enable
 * the behaviour. without this hack, scrolling feels gross.
 */
@mixin ios-smooth-scroll-fix() {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
