///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Basic */

// MSIE: Required for IEMobile.
@-ms-viewport {
	width: device-width;
}

// MSIE: Prevents scrollbar from overlapping content.
#homePage {
	-ms-overflow-style: scrollbar;
}

// Ensures page width is always >=320px.
@include breakpoint('<=xsmall') {
	html, #homePage {
		min-width: 320px;
	}
}

#homePage {
	background: _palette(bg);

	// Stops initial animations until page loads.
		&.is-preload {
			*, *:before, *:after {
				@include vendor('animation', 'none !important');
				@include vendor('transition', 'none !important');
			}
		}
}
