.copiableValue {
  height: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);

}

.content {
  padding-left: 0.8em;
  padding-right: 0.2em;
}
