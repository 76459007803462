@import '../../responsive.scss';
@import '../../palette.scss';
@import '../../common.scss';

.dashboard {
  overflow: hidden;
}

.sidebar {
  height: 100%;
  width: 250px;
  background-color: $accent2-alt;
  color: white;

  > * {
    padding: 1em;

    &:hover, &:global(.active) {
      background-color: lighten($accent2-alt, 10%);
    }
  }

  * {
    &:focus {
      border: none !important;
      outline: none !important;
    }
  }

  @include media-sm-down {
    display: none;
  }
}

.content {
  background-color: white;
  overflow: auto;
  @include ios-smooth-scroll-fix();

  > * {
    padding: 2rem;

    @include media-md-down {
      padding: 0.5rem;
    }
  }
}
