$navbar-background-color: #5052b5;

@import "~@blueprintjs/core/src/blueprint.scss";

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.bp3-input-group .bp3-input {
  width: auto;
  text-overflow: ellipsis;
}

.bp3-input-group {
  // icons inside inputs aren't correctly centered when the height of the input changes
  // this is a fix for that.
  position: relative;

  .bp3-icon {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bp3-navbar {
  color: white;
}

.bp3-callout {
  width: auto;
  max-width: 600px;
}
