.metric {
  width: 150px;
  height: 130px;
  font-size: 16px;
}

.label {
  color: grey;
  text-align: center;
}

.value {
  font-weight: bold;
  font-size: 1.7em;
}
